import React from "react";

function SuplierFilter(props) {
  const filterValue = (e) => {
    props.filterValueSelected(e.target.value)
  };

  return (
    <>
      <select
        class="form-select form-control"
        aria-label="Default select example"
        onChange={filterValue}
      >
        <option value="all" selected>All</option>
        <option value="apple-books">Apple Books</option>
        <option value="google-books">Google Books</option>
      </select>
    </>
  );
}

export default SuplierFilter;
