import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import appleBookIc from "../assets/png/apple-book.png";

function BookLand() {
  const location = useLocation();
  const { from } = location.state;
  const descArray = [];

  console.log('from', from)

  const bookProperties = {
    genre: from["badges-caption"][0],
    released: `${from["badges-value"][0]} ${from["badges-caption"][1]}`,
    langCode: from["badges-value"][1],
    language: from["badges-caption"][2],
    length: from["badges-value"][2],
    publisher: from["badges-caption"][4],
    seller: from["badges-caption"][5],
    size: from["badges-value"][3],
  };

  const descBuilder = () => {
    for (let i = 0; i < from.description.length; i++) {
      const element = from.description[i];
      console.log("element", element);
      descArray.push(element);
    }
  };
  descBuilder();

  return (
    <div className="container" style={{ marginTop: "6rem" }}>
      <div className="row my-5">
        <div className="col-8">
          <div className="d-inline w-100">
            <div className="d-flex justify-content-between flex-row-reverse text-light">
              <h1 className="rtl">{from.title}</h1>
              <h2>{from.price}</h2>
            </div>

            <p className="rtl">{from.author}</p>
            <div className="w-100 rtl">
              {descArray >= 2 ? (
                descArray.map((item, index) => <p key={index}>{item}</p>)
              ) : (
                <p>{descArray[0]}</p>
              )}
            </div>
          </div>

          <table className="table text-light my-5">
            <thead>
              <tr>
                {from["badges-eyebrow"].map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* {from.badges_value.map((item, index) => (
                  <td key={index}>{item}</td>
                ))} */}
                <td>{bookProperties.genre}</td>
                <td>{bookProperties.released}</td>
                <td>{bookProperties.language}</td>
                <td>{bookProperties.length}</td>
                <td>{bookProperties.publisher}</td>
                <td>{bookProperties.seller}</td>
                <td>{bookProperties.size}</td>
              </tr>
              {/* <tr>
                {from.badges_caption.map((item, index) => (
                  <td key={index}>{item}</td>
                ))}
              </tr> */}
            </tbody>
          </table>

          <div className="row" style={{marginTop: '120px'}}>
            <div className="col-4">
              <div className="d-flex">
                <img
                  className="mx-2"
                  style={{ width: "32px", height: "32px" }}
                  src={appleBookIc}
                  rel="apple-book"
                />
                <p>{from.suplier}</p>
              </div>
            </div>
            <div className="col-4">
              <p>{from.rating}</p>
            </div>
            <div className="col-4">
              <a
                className="btn btn-outline-primary w-100 text-center"
                href={from.url}
                target="_blank"
              >
                Buy now!
              </a>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-center text-center">
            <div
              className="m-3 bg-light rounded-2 position-relativ border border-secondary-subtle"
              style={{ width: "240px" }}
            >
              <img srcSet={from.image[1]} className="w-100 d-flex rounded-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookLand;
