import React, { useState } from "react";

function AuthorFilter(props) {
  const authorFilterListArray = [];

  const onCheck = (status, id) => {
    authorFilterListArray.push({ status, id });
    // console.log('authorFilterListArray', authorFilterListArray)
  };

  const onSubmit = () => {
    props.FilterAuthorSelected(authorFilterListArray);
  };

  return (
    <>
      {!props.data == ""
        ? props.data.map((item, index) => (
            <div key={index} class="form-check text-white-50">
              <input
                id={item.Author_ID}
                class="form-check-input"
                type="checkbox"
                // checked={onCheck}
                onChange={(e) => {
                  // console.log("check", e.target.checked, item.Author_ID);
                  onCheck(e.target.checked, item.Author_ID);
                }}
              />
              <label class="form-check-label" for={item.Author_ID}>
                <small>{item.Author}</small>
              </label>
            </div>
          ))
        : "Loading..."}
      <div className="btn btn-primary" onClick={onSubmit}>
        submit
      </div>
    </>
  );
}

export default AuthorFilter;
