import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

import BookCard from "./bookCard";
import SuplierFilter from "./suplierFilter";
import AuthorFilter from "./authorFilter";
import jsonData from "../assets/data/AppleBookCompiled.json"

function Books() {
  const [booksData, setBooksData] = useState("");
  const [authorsData, setAuthorsData] = useState("");
  const [filter, setfilter] = useState("all");
  const [filterAuthor, setFilterAuthor] = useState("all");

  // read JSON
  const obj = JSON.stringify(jsonData);
  const objParsed = JSON.parse(obj);
  console.log("json", objParsed)




  useEffect(() => {
    setBooksData(objParsed.books);
    // axios
    //   .get("http://localhost:3000/books")
    //   .then((response) => {
    //     // handle success
    //     setBooksData(response.data);
    //   })
    //   .catch((error) => {
    //     // handle error
    //     console.log(error);
    //   })
    //   .finally((response) => {
    //     // always executed
    //   });
  }, []);

  useEffect(() => {
    setAuthorsData(objParsed.authors);
    // axios
    //   .get("http://localhost:3000/authors")
    //   .then((response) => {
    //     // handle success
    //     setAuthorsData(response.data);
    //   })
    //   .catch((error) => {
    //     // handle error
    //     console.log(error);
    //   })
    //   .finally((response) => {
    //     // always executed
    //   });
  }, []);

  const filterProductList = [...booksData].filter((item) => {
    if (filter === "apple-books") {
      return item.suplier === "apple";
    } else if (filter === "google-books") {
      return item.suplier === "google";
    } else {
      return item;
    }
  });

  const onFilterValueSelected = (value) => {
    setfilter(value);
  };

  const onFilterAuthorSelected = (value) => {
    setFilterAuthor(value);
    console.log(value);
  };

  return (
    <>
      <div className="row my-5">
        <div className="col-9">
          <div className="d-flex justify-content-start mt-5">
            <h1 className="text-light">Top Books & and Refrences</h1>
          </div>
        </div>
        <div className="col-3">
          <label className="text-muted mt-4">Suplier</label>
          <SuplierFilter filterValueSelected={onFilterValueSelected} />
      </div>

        
          {/* <label className="text-muted mt-4">Authors</label>
          <AuthorFilter data={authorsData} FilterAuthorSelected={onFilterAuthorSelected}/> */}

      </div>
      <div className="row">
        <div className="col-12">
          <BookCard data={filterProductList} />
        </div>
      </div>
    </>
  );
}

export default Books;
