import React, { useState } from "react";
import appleBookIc from "../assets/png/apple-book.png";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import BookLand from "../routes/bookLand";

function BookCard(props) {
  const [itemToShow, setItemToShow] = useState(12);
  const [showMoreBtn, setShowMoreBtn] = useState(true);

  const showMore = () => {
    if (showMoreBtn == true) {
      setItemToShow(props.data.length);
      setShowMoreBtn(false);
    }

    if (showMoreBtn == false) {
      setItemToShow(12);
      setShowMoreBtn(true);
    }
  };
  return (
    <>
      <div className="row">
        {!props.data == ""
          ? props.data.slice(0, itemToShow).map((book) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-2 ">
                <Link to={`books/${book.uuid}`} state={{ from: book }}>
                  <div
                    className="card mb-5 product-card"
                    style={{ maxHeight: "420px", minHeight: "420px" }}
                  >
                    <div className="d-flex justify-content-center text-center">
                      <div
                        className="m-3 bg-light rounded-2 position-relativ border border-secondary-subtle"
                        style={{ width: "140px" }}
                      >
                        <img
                          srcset={book.image[1]}
                          className="w-100 d-flex rounded-2"
                        />
                      </div>
                    </div>
                    <div className="card-body d-flex flex-column">
                      <div className="w-100 text-center rtl">
                        <h6 className="text-light">{book.title}</h6>
                        <small>{book.author}</small>
                      </div>

                      <div className="d-flex justify-content-between w-100 mt-auto align-self-end">
                        <p>{book.rating}</p>
                        <h5 className="text-light">{book.price}</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          : "Loading..."}
      </div>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={showMore}
              style={{ width: "220px" }}
            >
              {showMoreBtn == true
                ? `Show More (${props.data.length} items)`
                : "Show Less"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookCard;
