import React from "react";

function SegmentHeader(props) {
  return (
    <div className="text-light">
      <h2>{props.title}</h2>
      <p>{props.desc}</p>
    </div>
  );
}

export default SegmentHeader;
