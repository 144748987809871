import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <div className="position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block"></div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="d-flex justify-content-center h-100 py-4">
                <small className="mt-4 text-light">
                  {t("footer-copy", { year: year })}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
