import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "nav-item-product-and-services": "Home",
            "nav-item-about-us": "About us",

            "nav-item-contact-us": "Contact us",
            "nav-item-english": "English",
            "nav-item-persian": "Persian",
            "footer-copy": "Copyright © {{year}} VisionBooks Inc. All rights reserved",
            "product-and-services-title": "Product & Services",
            "product-and-services-desc": "Yecom Pishgaman Daneshpozheh Mana (VisionBook) was established in 2020. On the surface, VisionBook is a startup, but on the inside, it consists of an expert and elite team with a history of more than 15 years in the field of specialized programming and a background mixed with the publishing industry and cultural stores. With a unique perspective, VisionBook has been looking for a revolution in this industry, to replace the traditional and dry responsibility-oriented processes with a modern and flexible process-oriented format.",
            "about-us-title": "About us",
            "about-us-desc": "VisionBook was established in 2020. On the surface, VisionBook is a startup, but on the inside, it consists of an expert and elite team with a history of more than 15 years in the field of specialized programming and a background mixed with the publishing industry and cultural stores. With a unique perspective, VisionBook has been looking for a revolution in this industry, to replace the traditional and dry responsibility-oriented processes with a modern and flexible process-oriented format.",
            "contact-us-title": "Contact us",
            "contact-us-desc": "If you have any questions, please send us your message through the form. Colleagues will contact you as soon as possible.",
            "total-number-products": "Total number of our products:",
            "slide-show-title-01": "Complete, versatile and powerful",
            "slide-show-desc-01": "Designed for both, simplicity and power",
            "slide-show-title-02": "A Community like No Other",
            "slide-show-desc-02": "A Community like No Other",
            "slide-show-title-03": "Collaboration",
            "slide-show-desc-03": "Collaboration",
            "slide-show-title-04": "Transparency",
            "slide-show-desc-04": "Transparency",
            "welcome-libra-kdr": "LIBRA-KDR",
            "welcome-academia": "LIBRA Academia",
            "welcome-rodin": "Rodin",
            "welcome-ai": "AI Department",

            "welcome-footer-show-more": "Show More",
            "welcome-footer-read-more": "Read More",

            "segment-libra-kdr-title": "LIBRA Knowledge Discovery and Representation",
            "segment-libra-kdr-desc": "LIBRA knowledge discovery and representation system (LIBRA-KDR), is a comprehensive and integrated management system of all kinds of information resources with ...",
            "segment-academia-title": "LIBRA Academia Application",
            "segment-academia-desc": "The use of up-to-date technologies and accurate business and information retrieval modelsin this system will significantly reduce the cost of education and also create atremendous transformation in the link between the author, translator, publisher andfinally the target audience.",
            "segment-rodin-title": "Rodin shopping system",
            "segment-rodin-desc": "Rodin software has started its activity in the field of sales management and accounting of cultural stores since 2002 and has been developed with the vision of centering the process of executive routines, providing mechanized workflow and high compatibility when in the store...",
            "segment-ai-title": "Department of artificial intelligence",
            "segment-ai-desc": "VisionBook artificial intelligence unit researches and develops new artificial intelligence algorithms, and implements and changes existing algorithms. Research...",
            "contact-us-form-label-tel": "Tel:",
            "contact-us-form-label-email": "Email:",
            "contact-us-form-first-name": "First Name",
            "contact-us-form-last-name": "Last Name",
            "contact-us-form-email": "Enter your email...",
            "contact-us-form-message": "your message...",
            "contact-us-form-submit": "Submit",
            "about-us-users-number": "Total number of users using our products:",

            "typewriter-1": "Activity in the fields of standardization",
            "typewriter-2": "Synchronized with the latest technologies",

            "see-more-btn": "See More",

            "kdr-introduction-title": "LIBRA-KDR Introduction",
            "kdr-introduction-desc": "LIBRA knowledge discovery and representation system (LIBRA-KDR) is a comprehensive and integrated management system of all kinds of information resources with a context-oriented approach and based on a modular architecture model (modularity), with the aim of meeting the various needs of all kinds of libraries, information centers and others. Designed and implemented accessible information providers. This system supports a wide range of functions required by libraries and information centers by benefiting from the most important and up-to-date information management standards and by using an efficient technical infrastructure and platform in accordance with the used standards. Also, in addition to facilitating and speeding up access to the local content of libraries and information centers, it provides access to the content of other systems and databases (shared and open access) in a completely integrated way and in the form of a bibliographic discovery module.",

            "kdr-features-title": "Key Features",
            "kdr-features-list-1": "Unique data architecture with a hybrid and innovative approach based on conceptual models, data",
            "kdr-features-list-2": "Models and information and knowledge management standards.",
            "kdr-features-list-3": "Utilizing up-to-date and valid international standards to manage all types of data entities with",
            "kdr-features-list-4": "Localization capability (application profiles).",
            "kdr-features-list-5": "Designing the user interface environment UI modules for organizing information and knowledge of",
            "kdr-features-list-6": "Documentation, and retrieving and displaying information based on the design of the BIBFREAME",
            "kdr-features-list-7": "Standard editor interface.",
            "kdr-features-list-8": "Multi-layered and web-based architecture with the possibility of local development (Localization)",
            "kdr-features-list-9": "Ontological approach in system design with the ability to define all types of data entities and the",
            "kdr-features-list-10": "possibility of customizing their attributes",
            "kdr-features-list-11": "Multilingual support in all levels and environments",
            "kdr-features-list-12": "Flexible technical infrastructure with the possibility of quick and easy development",
            "kdr-features-list-13": "API-based infrastructure to provide access and connectivity to other infrastructures",

            "kdr-system-modules-title": "System modules",
            "kdr-system-modules-list-1": "Information and knowledge organization module",
            "kdr-system-modules-list-2": "Documentation module",
            "kdr-system-modules-list-3": "Resource circulation and trust management module",
            "kdr-system-modules-list-4": "OPAC Module",
            "kdr-system-modules-list-5": "Information retrieval and display module",
            "kdr-system-modules-list-6": "Management module and access levels",
            "kdr-system-modules-list-7": "Content object management module (physical and digital)",
            "kdr-system-modules-list-8": "Information provision and document delivery module",
            "kdr-system-modules-list-9": "Periodicals module",
            "kdr-system-modules-list-10": "Introducing LIBRA-BDS bibliographic discovery system",

            "kdr-system-modules-list-1b": "Managing all types of metadata and creating functional profiles",
            "kdr-system-modules-list-2b": "Existence of 'nomenclature' and uniformity in the description of access points",
            "kdr-system-modules-list-3b": "Comprehensive management of resource circulation process",
            "kdr-system-modules-list-4b": "Interactive list with various features",
            "kdr-system-modules-list-5b": "Representation of relations and semantic base in the form of knowledge graphs",
            "kdr-system-modules-list-6b": "Flexibility in managing the access of different user groups",
            "kdr-system-modules-list-7b": "Integrated and two-dimensional management of cultural heritage objects",
            "kdr-system-modules-list-8b": "An efficient tool for collection and meeting the needs of users",
            "kdr-system-modules-list-9b": "Integrated management of connections",
            "kdr-system-modules-list-10b": "Extensive and integrated access to resources outside the library from the local system environment",

            "kdr-Standards-title": "Standards and technologies",
            "kdr-Standards-list-1": "Software production with C# programming language on the dotnet Core platform",
            "kdr-Standards-list-2": "Deploying in a safe and secure UBUNTU Linux environment",
            "kdr-Standards-list-3": "Manage data using MongoDB's flexible tools",
            "kdr-Standards-list-4": "Elasticsearch search engine",
            "kdr-Standards-list-5": "System architecture based on cultural heritage conceptual refrence models (CHC CRM)",
            "kdr-Standards-list-6": "Support Resource Description and Access (RDA) Standard",
            "kdr-Standards-list-7": "JSON-LD format as the main syntactic platform of metadata profiles",
            "kdr-Standards-list-8": "The basic metadata standard of the system based on the most comprehensive org structured data production process with localization capability.",
            "kdr-Standards-list-9": "BIBFRAME standard for compatibility and greater applicability of org standard with library context and user interface design",
            "kdr-Standards-list-10": "Interoperability protocols of information systems including Z 39.50 and OAI-PMH protocols",

            "kdr-Standards-list-1b": "Conceptual model of LRM as a basis in libraries",
            "kdr-Standards-list-2b": "CIDOC and RiC conceptual models for other cultural contexts",

            "kdr-download-doc": "Download Full Documention",
            "kdr-other-products": "Other Products",
            "kdr-share-friends": "Share with  your friends",

            "academia-introduction-title": "Libra Academia Introduction",
            "academia-introduction-desc-1": "Libra Academia is the result of 15 years of useful work experience with the best publishers and international information providers in the publishing and research industry.",
            "academia-introduction-desc-2": "The use of up-to-date technologies and accurate business and information retrieval models in this system will significantly reduce the cost of education and also create a tremendous transformation in the link between the author, translator, publisher and finally the target audience.",
            "academia-introduction-desc-3": "VisionBook is the official representative of American Vision Books (www.visionbookspublisher.com) publishing company in Iran, at the request of this company for its customers (scientific research institutions in the field of Persian literature, Iranian studies, Oriental studies, etc.) that are mostly in America and Europe is active, is trying to collect a database of the country's publications and provide access to your content for researchers abroad in the form of subscription.",

            "academia-institutions-title": "Areas requested by institutions:",
            "academia-institutions-list-1": "Persian the literature",
            "academia-institutions-list-2": "Iranology",
            "academia-institutions-list-3": "Linguistics",
            "academia-institutions-list-4": "Iran History",
            "academia-institutions-list-5": "History of Shia and Islam",
            "academia-institutions-list-6": "Islamic Studies - Oriental Studies",
            "academia-institutions-list-7": "Archaeology",
            "academia-institutions-list-8": "Sociology",
            "academia-institutions-list-9": "Iranian religions",
            "academia-institutions-list-10": "Iranian mythology",
            "academia-institutions-list-11": "Iranian and Islamic art",

            "academia-institutions-desc-1": "Currently, the operational plan of the system is being implemented in the following four universities on a trial basis, and it will be a pleasure and an honor to be able to introduce your publications to these institutions.",
            "academia-institutions-desc-2": "If you want to add your publications to the Libra Academy database and sell it to foreign institutions, it is suggested that we have a meeting regarding the additional explanations of the business model and cooperation frameworks.",
            
            "rodin-introduction-title" : "Rodin Introduction",
            "rodin-introduction-desc-1" : "Rodin software has started its activity in the field of sales management and accounting of cultural stores since 2002 and has been developed with the vision of centering the process of executive routines, providing mechanized workflow and high compatibility when in the store.",
            "rodin-introduction-desc-2" : "Currently, our goal is to increase the efficiency of the store, which by using the facilities and features of different departments of Rodin, in practice, will reduce capital stagnation, increase the variety of products and obtain various management reports, in order to make optimal store decisions, and our goal will be achieved.",
        
            "rodin-professional-cashier-title" : "Professional store cashier",
            "rodin-professional-cashier-list-1" : "Dedicated sales invoice for the store cash register, with the possibility of working with a barcode reader and connected to the bank account.",
            "rodin-professional-cashier-list-2" : "Fast invoice function, with the possibility to display the image of the product in order to avoid common mistakes.",
            "rodin-professional-cashier-list-3" : "Simple payment form, for the convenience of the user.",
            "rodin-professional-cashier-list-4" : "Customer club, connected to the checkout form and automatic step discount function at the moment of sale.",
            "rodin-professional-cashier-list-5" : "Non-cash sales invoice with the possibility of selling based on the barcode and specifying the account party and the method of settlement.",
            
            "rodin-product-information-title" : "Product information management",
            "rodin-product-information-list-1" : "Product definition with required details, especially for cultural goods such as books, handicrafts, stationery, etc.",
            "rodin-product-information-list-2" : "Mandatory fields management for product definition.",
            "rodin-product-information-list-3" : "Managing the accuracy and consistency of product information, by preventing the registration of duplicate products and the possibility of creating a dedicated barcode.",
            "rodin-product-information-list-4" : "The possibility of setting the system performance in a consistent manner, such as the definitions of automatic sales document registration.",
            "rodin-product-information-list-5" : "The possibility of defining an unlimited number of users and grouping users based on work roles.",
            "rodin-product-information-list-6" : "Determining the right of access for users to the details of the forms.",
            "rodin-product-information-list-7" : "Ability to define an unlimited number of categories.",
            "rodin-product-information-list-8" : "Defining people coherently and determining the role of people in the workflow, such as producer, seller, buyer",

            "rodin-financial-system-title" : "Comprehensive and integrated financial system",
            "rodin-financial-system-list-1" : "The possibility of defining the bank and bank account, as well as the possibility of defining the checkbook and check sheets.",
            "rodin-financial-system-list-2" : "Direct payment portal for Saman and Pasargad banks.",
            "rodin-financial-system-list-3" : "The possibility of entering information for receipt and payment forms, in groups and printing issued checks.",
            "rodin-financial-system-list-4" : "The possibility of reporting and determining the check status at each stage.",
            "rodin-financial-system-list-5" : "Accounting connected to the sales system, with the possibility of registering sales documents automatically at the moment of registration.",
            "rodin-financial-system-list-6" : "The possibility of performing accounting operations, permanently or periodically.",
            "rodin-financial-system-list-7" : "Calculation of the cost of goods sold, based on the weighted variable average formula.",
            "rodin-financial-system-list-8" : "Defining the financial period and registering opening and closing documents automatically and creating restrictions for system performance based on the financial period.",
            "rodin-financial-system-list-9" : "The possibility of defining the turn of the fund and auditing by cashiers with the possibility of automatic calculation and excess of the fund.",
            "rodin-financial-system-list-10" : "The ability to lock the invoices of the sales department, based on the date of handling the accounts, so that the editing of the invoices will be disabled.",

            "rodin-customer-club-title" : "Flexible customer club",
            "rodin-customer-club-list-1" : "The possibility of defining product discount rules based on time, product and customer status, unlimited and automatically applied at the store checkout.",
            "rodin-customer-club-list-2" : "Defining how to score points for club members, based on performance and purchase volume.",
            "rodin-customer-club-list-3" : "The possibility of sending SMS and informing about the amount of points and credits after each purchase.",
            "rodin-customer-club-list-4" : "Ability to send happy birthday messages to members automatically.",

            "rodin-centralized-purchase-title" : "Defining",
            "rodin-centralized-purchase-list-1" : "Suggesting the purchase of goods by the system automatically, based on the way the goods are sold, taking into account the speed of the goods sale.",
            "rodin-centralized-purchase-list-2" : "Consistent management of the goods ordered to the supplier and the time of receiving the goods, with the possibility of pre-purchase registration, in order to control the correctness of the supplier's performance.",
            "rodin-centralized-purchase-list-3" : "The ability to approve the department manager before placing an order for the supply of goods.",
            "rodin-centralized-purchase-list-4" : "Registration of purchase and return invoices with convenient facilities and reference invoices.",
            "rodin-centralized-purchase-list-5" : "The possibility of determining the settlement time in the purchase invoice and budgeting based on the settlement estimate.",
            "rodin-centralized-purchase-list-6" : "Application form for entering product information in invoice registration, with the possibility of searching based on product name or product barcode.",
            "rodin-centralized-purchase-list-7" : "Displaying additional information at the time of registering the purchase invoice, in order to avoid buying the wrong product.",
            
            "rodin-cafe-title" : "Cafe and restaurant",
            "rodin-cafe-list-1" : "Managing and issuing sales invoices, based on available space and desks.",
            "rodin-cafe-list-2" : "Connected to the store's customer club.",
            "rodin-cafe-list-3" : "Calculating the cost of goods based on the manufacturing formula.",
            "rodin-cafe-list-4" : "Storage of raw materials and goods ready for sale, separately",

            "rodin-capillary-sales-title" : "Capillary sales",
            "rodin-capillary-sales-list-1" : "Android application for issuing sales invoices or pre-invoices online at the customer's location.",
            "rodin-capillary-sales-list-2" : "Show inventory online and up-to-date.",
            "rodin-capillary-sales-list-3" : "Compatible with all internet lines without the need for a special internet platform.",
            "rodin-capillary-sales-list-4" : "Quick search.",
            "rodin-capillary-sales-list-5" : "Display product information in full, along with the image.",

            "rodin-Store-title" : "Store",
            "rodin-Store-list-1" : "Ability to edit product information in groups.",
            "rodin-Store-list-2" : "The versatility of the software, along with the possibility of defining the location code and connecting to the goods.",
            "rodin-Store-list-3" : "The possibility of warehouse management by section, based on the location code, simply and with a short time (possibility of warehouse management on a daily basis).",
            "rodin-Store-list-4" : "Registering receipts and warehouse transfers, manually or automatically.",
            "rodin-Store-list-5" : "The possibility of producing labels for goods compatible with special printers for label printing.",

            "ai-introduction-title" : "Department of artificial intelligence",
            "ai-introduction-desc" : "VisionBook artificial intelligence unit researches and develops new artificial intelligence algorithms, and implements and changes existing algorithms. Research work is done on new algorithms, and they are localized according to the needs of the Persian-speaking community. Also, this unit develops its own algorithms.",

            "ai-products-title" : "The two products developed in this unit are as follows:",

            "ai-products-shiva-title" : "Shiva",
            "ai-products-shiva-desc" : "VisionBook text-to-speech engine, which takes the raw text, reads it with a voice close to the human voice, and converts it into an audio file. For this engine, several modules have been used in the field of Persian text processing, text-to-phoneme conversion, and phoneme-to-voice conversion. Deep learning and natural language processing methods have been used in these modules, and the existing algorithms have been changed in such a way that they can have a tone as close as possible to Persian speakers. The accuracy of Shiva is comparable to the accuracy of the latest text-to-speech engines in different languages ​​of the world. Using Shiva, text books are converted into audio books and made available to users. Shiva is also used as a separate product.",
            
            "ai-products-recommender-title" : "Recommender system",
            "ai-products-recommender-desc" : "In this system, which is used to recommend items to users, the previous methods are improved, and the work of recommending items is done in a personalized way, in such a way that for each user, based on the time, the user's interests, the characteristics of the items, and the trends in the Market, the desired items are suggested. This recommender system has shown good performance.",
        
            "testomonial-davood-name": "Davood Kiani",
            "testomonial-davood-title": "Chief Executive Officer",
            "testomonial-davood-desc": "Products which has best Performance",

            "testomonial-faryar-name": "Faryar Fatemi",
            "testomonial-faryar-title": "Chief Sales Officer",
            "testomonial-faryar-desc": "Products which has best Performance",

            "testomonial-farima-name": "Farima Lari",
            "testomonial-farima-title": "Chief Product Officer",
            "testomonial-farima-desc": "Products which has best Performance",

            "testomonial-erfan-name": "Erfan Mohammadi",
            "testomonial-erfan-title": "Chief Technology Officer",
            "testomonial-erfan-desc": "Products which has best Performance",


        }
    },
    fa: {
        translation: {
            "nav-item-product-and-services": "محصولات و خدمات",
            "nav-item-about-us": "درباره ما",
            "nav-item-contact-us": "تماس با ما",
            "nav-item-english": "انگلیسی",
            "nav-item-persian": "فارسی",
            "footer-copy": "کلیه حقوق مادی و معنوی برای شرکت یکم سافت محفوظ است © ۲۰۲۳",
            "product-and-services-title": "محصولات و خدمات",
            "product-and-services-desc": "سیستم کشف و بازنمود دانش لیبرا (LIBRA-KDR)، یک سیستم مدیریت جامع و یکپارچه انواع منابع اطلاعاتی است که با رویکرد بافت-مدار و مبتنی بر مدل معماری پیمانه‌ای (ماژولاریتی)، و با هدف پاسخگویی به نیازهای گوناگون انواع کتابخانه‌ها و مراکز اطلاعاتی، و دیگر فراهم‌کنندگان اطلاعات دسترس‌پذیر طراحی و پیاده‌سازی شده است.",
            "about-us-title": "درباره ما",
            "about-us-desc": "شرکت دانش‌بنیان یکم پیشگامان دانش‌پژوه مانا (یکم‌سافت)، در سال ۹۹ تأسیس شد. یکم‌سافت در ظاهر، استارت‌آپی نوپا است اما از درون، متشکل از تیمی متخصص و نخبه با سابقه‌ای بیش از ۱۵ سال در حوزه‌ی برنامه نویسی تخصصی و پیشینه‌ای آمیخته با صنعت نشر و فروشگاه‌ فرهنگی است. یکم‌سافت با نگاهی منحصربه‌فرد، به دنبال انقلابی در این صنعت بوده، تا روند‌‌های سنتی و خشک مسئولیت محور را، با قالب مدرن و منعطف فرایند محور جایگزین کند.",
            "contact-us-title": "تماس با  ما",
            "contact-us-desc": "در صورت وجود هر گونه سوال لطفا پیام خود را از طریق فرم برای ما ارسال کنید. همکاران در اسرع وقت با شما تماس خواهند گرفت.",
            "total-number-products": "تعداد کل محصولات ما:",
            "slide-show-title-01": "کامل، جذاب و قدرتمند",
            "slide-show-desc-01": "طراحی شده برای بهرهمندی از سادگی و قدرت",
            "slide-show-title-02": "محیط اجتماعی منحصر به فرد",
            "slide-show-desc-02": "محیط اجتماعی منحصر به فرد",
            "slide-show-title-03": "همکاری",
            "slide-show-desc-03": "همکاری",
            "slide-show-title-04": "شفافیت",
            "slide-show-desc-04": "شفافیت",
            "welcome-libra-kdr": "لیبرا کی دی آر",
            "welcome-academia": "لیبرا آکادمیا",
            "welcome-rodin": "رادین",
            "welcome-ai": "هوش مصنوعی",

            "welcome-footer-show-more": "نمایش بیشتر",
            "welcome-footer-read-more": "در مورد سرویس‌ها بیشتر بدانید",

            "segment-libra-kdr-title": "نرم‌افزار کشف و بازنمود دانش لیبرا",
            "segment-libra-kdr-desc": "نرم‌افزار كشف و بازنمود دانش لیبرا (LIBRA-KDR)، یک سیستم مدیریت جامع و یکپارچه انواع منابع اطلاعاتی است که با رویکرد بافت-مدار و مبتنی بر مدل معماری پیمانه‌ای (ماژولاریتی)، با هدف پاسخگویی به نیازهای گوناگون انواع کتابخانه‌ها، مراکز اسنادی (آرشیوی)، موزه‌ها و دیگر فراهم‌کنندگان اطلاعات دسترس‌پذیر طراحی و پیاده‌سازی شده است.",
            "segment-academia-title": "اپلیکیشن لیبرا آکادمیا",
            "segment-academia-desc": " لیبرا آکادمیا سرویسی برای جمع‌آوری پایگاه‌ داده‌ای از نشریات کشور جهت نمایش، دسترس‌پذیرنمودن و فروش بین‌المللی در قالب آبونمان کردن آثار و نشریات در حوزه‌های ادبیات فارسی، ایرانشناسی، خاور شناسی و...",
            "segment-rodin-title": "سامانه جامع فروشگاهی رادین",
            "segment-rodin-desc": "نرم‌‌افزار رادین از سال ۱۳۸۲ فعالیتش را در حوزه‌ مدیریت فروش و حسابداری فروشگاه‌های فرهنگی، آغاز و با چشم‌انداز فرایند محورکردن روال‌های اجرایی، ارائه گردش کار مکانیزه و سازگاری بالا هنگام قرار گرفتن در فروشگاه، توسعه یافته است. در حال حاضر هدف ما بالا بردن راندمان فروشگاه است که با استفاده از امکانات و ویژگی‌های بخش‌های مختلف رادین، در عمل، باعث کاهش رکود سرمایه، افزایش تنوع کالا و اخذ گزارشات متنوع مدیریتی، جهت تصمیم‌گیری فروشگاه بهینه شده و هدف ما محقق می‌شود.",
            "segment-ai-title": "دپارتمان هوش مصنوعی",
            "segment-ai-desc": "واحد هوش مصنوعی یکم‌سافت به تحقیق و توسعه الگوریتم‌های جدید هوش مصنوعی، و پیاده‌سازی و تغییر در الگوریتم‌های موجود می‌پردازد. کار پژوهش بر روی الگوریتم‌های جدید انجام می‌شود، و آن‌ها مطابق با نیازهای جامعه فارسی‌زبان بومی‌سازی می‌شوند. همچنین این واحد الگوریتم‌های خود را هم توسعه می‌دهد.",
            "contact-us-form-label-tel": "تلفن:",
            "contact-us-form-label-email": "ایمیل:",
            "contact-us-form-first-name": "نام",
            "contact-us-form-last-name": "نام خانوادگی",
            "contact-us-form-email": "ایمیل خود را وارد کنید...",
            "contact-us-form-message": "پیام شما...",
            "contact-us-form-submit": "ارسال",
            "about-us-users-number": "تعداد کل کاربرانی که از محصولات ما استفاده می‌کنند:",

            "typewriter-1": "فعالیت در حوزه‌های استانداردسازی",
            "typewriter-2": "همگام با تکنولوژی‌های روز دنیا",

            "see-more-btn": "بیشتر",

            "kdr-introduction-title": "معرفی نرم‌افزار کشف و بازنمود دانش لیبرا ",
            "kdr-introduction-desc": "سیستم کشف و بازنمود دانش لیبرا (LIBRA-KDR)، یک سیستم مدیریت جامع و یکپارچه انواع منابع اطلاعاتی است که با رویکرد بافت-مدار و مبتنی بر مدل معماری پیمانه‌ای (ماژولاریتی)، با هدف پاسخگویی به نیازهای گوناگون انواع کتابخانه‌ها، مراکز اطلاعاتی و دیگر فراهم‌کنندگان اطلاعات دسترس‌پذیر، طراحی و پیاده‌سازی شده است. این سیستم با بهره‌مندی از مهمترین و روزآمدترین استاندارهای مدیریت اطلاعات و با استفاده از زیرساخت و بستر فنی کارآمد و متناسب با استانداردهای مورد استفاده، طیف گسترده‌ای از کارکردهای مورد نیاز کتابخانه‌ها و مراکز اطلاعاتی را پشتبیانی می‌کند. نیز، افزون بر تسهیل و تسریع دسترسی به محتوای محلی کتابخانه‌ها و مراکز اطلاعاتی، دسترسی به محتوای دیگر سیستم‌ها و پایگاه‌های اطلاعاتی (اشتراکی و دسترسی باز) را با شیوه‌ای کاملاً یکپارچه و در قالب ماژول کشف کتابشناختی فراهم می‌نماید.",

            "kdr-features-title": "ویژگی‌های کلیدی",
            "kdr-features-list-1": "معماری داده‌ای منحصر بفرد با رویکرد ترکیبی و نوآورانه بر پایه مدل‌های مفهومی، مدل‌های داده‌ای و استانداردهای مدیریت اطلاعات و دانش.",
            "kdr-features-list-2": "بهره‌گیری از استانداردهای بین‌المللی روزآمد و معتبر برای مدیریت انواع موجودیت‌های داده‌ای با قابلیت بومی‌سازی (پروفایل‌های کاربردی).",
            "kdr-features-list-3": "طراحی محیط رابط کاربر UI ماژول‌های سازماندهی اطلاعات و دانش مستندات، و بازیابی و نمایش اطلاعات بر اساس طرح اینترفیس ویراستار استاندارد BIBFREAME.",
            "kdr-features-list-4": "معماری چند لایه و مبتنی بر وب با امکان توسعه محلی (Localization)",
            "kdr-features-list-5": "رویکرد هستی‌شناسانه در طراحی سیستم با قابلیت تعریف انواع موجودیت‌های داده‌ای و امکان سفارشی‌سازی صفات آنها",
            "kdr-features-list-6": "پشتیبانی از قابلیت چندزبانگی در تمامی سطوح و محیط‌ها",
            "kdr-features-list-7": "زیرساخت فنی منعطف با امکان توسعه سریع و آسان",
            "kdr-features-list-8": "زیرساخت مبتنی بر پروتکل برنامه‌های کاربردی (API) برای اعطای دسترسی و امکان اتصال با دیگر زیرساخت‌ها",

            "kdr-system-modules-title": "ماژول‌های سیستم",
            "kdr-system-modules-list-1": "ماژول سازماندهی اطلاعات و دانش",
            "kdr-system-modules-list-2": "ماژول مستندات",
            "kdr-system-modules-list-3": "ماژول مدیریت گردش و امانت منابع",
            "kdr-system-modules-list-4": "ماژول فهرست دسترس‌پذیر همگانی (OPAC)",
            "kdr-system-modules-list-5": "ماژول بازیابی و نمایش اطلاعات",
            "kdr-system-modules-list-6": "ماژول مدیریت و سطوح دسترسی",
            "kdr-system-modules-list-7": "ماژول مدیریت اشیای محتوایی (فیزیکی و دیجیتالی)",
            "kdr-system-modules-list-8": "ماژول فراهم‌آوری اطلاعات و تحویل مدارک",
            "kdr-system-modules-list-9": "ماژول نشریات ادواری",
            "kdr-system-modules-list-10": "معرفی سیستم کشف کتابشناختی LIBRA-BDS",

            "kdr-system-modules-list-1b": "مدیریت انواع فراداده و ایجاد پروفایل‌های کاربردی",
            "kdr-system-modules-list-2b": "موجودیت «نام‌نما» و یکدستی در توصیف نقاط دسترسی",
            "kdr-system-modules-list-3b": "مدیریت جامع فرایند‌ گردش منابع",
            "kdr-system-modules-list-4b": "فهرست تعاملی با قابلیت‌های گوناگون",
            "kdr-system-modules-list-5b": "بازنمود روابط و بستر معنا شناختی در قالب گراف‌های دانش",
            "kdr-system-modules-list-6b": "انعطاف‌پذیری در مدیریت دسترسی گروه‌های مختلف کاربری",
            "kdr-system-modules-list-7b": "مدیریت یکپارچه و دو وجهی اشیا بافت میراث فرهنگی",
            "kdr-system-modules-list-8b": "ابزار کارآمد مجموعه‌سازی و تأمین نیاز‌های کاربران",
            "kdr-system-modules-list-9b": "مدیریت یکپارچه پیایند‌ها",
            "kdr-system-modules-list-10b": "دسترسی گسترده و یکپارچه به منابع خارج از کتابخانه از محیط سیستم محلی",

            "kdr-Standards-title": "استانداردها و فناوری‌ها",
            "kdr-Standards-list-1": "تولید نرم‌افزار با زبان برنامه‌نویسی C# در بستر dotnet Core",
            "kdr-Standards-list-2": "استقرار در محیط ایمن و مطمئن لینوکس UBUNTU",
            "kdr-Standards-list-3": "مدیریت داده‌ها با استفاده از ابزار منعطف MongoDB",
            "kdr-Standards-list-4": "موتور جستجوی Elasticsearch",
            "kdr-Standards-list-5": "معماری سیستم بر اساس مدل‌های مفهومی میراث فرهنگی CHC CRM",
            "kdr-Standards-list-6": "استاندارد توصیف و دسترسی به منبع RDA",
            "kdr-Standards-list-7": "قالب JSON-LD به عنوان بستر نحوی اصلی پروفایل‌های فراداده‌ای",
            "kdr-Standards-list-8": "استاندارد فراداده‌ای پایه و اصلی سیستم بر اساس جامع‌ترین فرانمای تولید داده‌های ساختارمند org با قابلیت بومی‌سازی",
            "kdr-Standards-list-9": "استاندارد BIBFRAME برای همخوانی و کاربردپذیری بیشتر استاندارد org با بافت کتابخانه‌ای و طراحی محیط رابط کاربر",
            "kdr-Standards-list-10": "پروتکل‌های میانکنش‌پذیری سیستم‌های اطلاعاتی Interoperability protocols شامل پروتکل‌های Z 39.50 و OAI-PMH",

            "kdr-Standards-list-1b": "مدل مفهومی ال‌آر‌ام LRM به عنوان مبنا در کتابخانه‌ها",
            "kdr-Standards-list-2b": "مدل‌های مفهومی CIDOC و RiC برای دیگر بافت‌های فرهنگی",

            "kdr-download-doc": "دانلود راهنما کامل",
            "kdr-other-products": "محصولات دیگر:",
            "kdr-share-friends": "با دوستان خود به اشتراک بگذارید:",

            "academia-introduction-title": "معرفی لیبرا آکادمیا",
            "academia-introduction-desc-1": "لیبرا آکادمیا حاصل ۱۵ سال تجربه‌ کار مفید با برترین ناشران و فراهم‌آورندگان اطلاعات بین‌المللی در صنعت نشر و پژوهش است.",
            "academia-introduction-desc-2": "استفاده از فناوری‌های روزآمد و مدل‌های دقیق بازیابی اطلاعات و کسب و کار در این سامانه، موجب کاهش چشم‌گیر هزینه‌ آموزش و همچنین ایجاد تحولی شگرف در پیوند بین نویسنده، مترجم، ناشر و در نهایت مخاطبان هدف می‌شود.",
            "academia-introduction-desc-3": "یکم‌سافت نماینده رسمی شرکت انتشاراتی Vision Books (www.visionbookspublisher.com) آمریکا در ایران، بنا به درخواست این شرکت برای مشتریان خود (موسسات علمی پژوهشی که در زمینه‌ ادبیات فارسی، ایرانشناسی، خاور شناسی و...) که بیشتر در آمریکا و اروپا فعالیت دارند، درصدد است پایگاه‌‌ داده‌ای از نشریات کشور، جمع آوری کرده و در قالب آبونمان، دسترسی به محتوای شما ناشرین عزیز را برای پژوهشگران خارج از کشور فراهم نماید.",

            "academia-institutions-title": "حوزه های مورد درخواست موسسات:",
            "academia-institutions-list-1": "ادبیات فارسی",
            "academia-institutions-list-2": "ایران شناسی",
            "academia-institutions-list-3": "زبانشناسی",
            "academia-institutions-list-4": "تاریخ ایران",
            "academia-institutions-list-5": "تاریخ شیعه و اسلام",
            "academia-institutions-list-6": "مطالعات اسلامی - شرق شناسی",
            "academia-institutions-list-7": "باستان شناسی",
            "academia-institutions-list-8": "جامعه شناسی",
            "academia-institutions-list-9": "ادیان ایرانی",
            "academia-institutions-list-10": "اساطیر ایرانی",
            "academia-institutions-list-11": "هنر ایرانی و اسلامی",

            "academia-institutions-desc-1": "هم‌اکنون طرح عملیاتی سامانه در چهار دانشگاه زیر به صورت آزمایشی در حال پیاده‌سازی است و باعث خوشحالی و افتخار خواهد بود که بتوانیم نشریات شما را به این موسسات معرفی کنیم.",
            "academia-institutions-desc-2": "پیشنهاد می شود در صورت تمایل به اضافه کردن نشریات خود به پایگاه داده ای Libra Academia و فروش آن به موسسات خارجی، ملاقاتی در خصوص توضیحات تکمیلی مدل تجاری و چهار چوب‌های همکاری داشته باشیم.",

            "rodin-introduction-title" : "معرفی سامانه جامع فروشگاهی رادین",
            "rodin-introduction-desc-1" : "نرم‌‌افزار رادین از سال ۱۳۸۲ فعالیتش را در حوزه‌ مدیریت فروش و حسابداری فروشگاه‌های فرهنگی، آغاز و با چشم‌انداز فرایند محورکردن روال‌های اجرایی، ارائه گردش کار مکانیزه و سازگاری بالا هنگام قرار گرفتن در فروشگاه، توسعه یافته است.",
            "rodin-introduction-desc-2" : "در حال حاضر هدف ما بالا بردن راندمان فروشگاه است که با استفاده از امکانات و ویژگی‌های بخش‌های مختلف رادین، در عمل، باعث کاهش رکود سرمایه، افزایش تنوع کالا و اخذ گزارشات متنوع مدیریتی، جهت تصمیم‌گیری فروشگاه بهینه شده و هدف ما محقق می‌شود.",
        
            "rodin-professional-cashier-title" : "صندوق فروشگاهی حرفه ای",
            "rodin-professional-cashier-list-1" : "فاکتور فروش اختصاصی صندوق فروشگاه، با امکان عملکرد با بارکدخوان و متصل به پوز بانکی.",
            "rodin-professional-cashier-list-2" : "عملکرد سریع فاکتور، با امکان نمایش تصویر کالا به منظور جلوگیری از اشتباهات رایج.",
            "rodin-professional-cashier-list-3" : "فرم تسویه حساب ساده، برای راحتی کاربر.",
            "rodin-professional-cashier-list-4" : "باشگاه مشتریان، متصل به فرم صندوق و عملکرد تخفیف پلکانی در لحظه‌ی فروش به‌صورت خودکار.",
            "rodin-professional-cashier-list-5" : "فاکتور فروش غیر نقدی با امکان فروش بر اساس بارکد و تعیین طرف حساب و نحوه‌ی تسویه.",
            
            "rodin-product-information-title" : "مدیریت اطلاعات کالا",
            "rodin-product-information-list-1" : "تعریف کالا با جزئیات مورد نیاز، خصوصا برای کالاهای فرهنگی مانند کتاب، صنایع دستی، لوازم تحریر و…",
            "rodin-product-information-list-2" : "مدیریت فیلدهای اجباری برای تعریف کالا.",
            "rodin-product-information-list-3" : "مدیریت صحت و انسجام اطلاعات کالا، با جلوگیری از ثبت کالای تکراری وامکان ایجاد بارکد اختصاصی.",
            "rodin-product-information-list-4" : "امکان تنظیم عملکرد سیستم به صورت منسجم، مانند تعاریف ثبت اسناد خودکار فروش.",
            "rodin-product-information-list-5" : "امکان تعریف کاربر به تعداد نامحدود و گروه بندی کاربران بر اساس نقش کاری.",
            "rodin-product-information-list-6" : "تعیین حق دسترسی برای کاربران به جزئیات فرم‌ها.",
            "rodin-product-information-list-7" : "امکان تعریف انواع دسته‌بندی به تعداد نامحدود.",
            "rodin-product-information-list-8" : "تعریف اشخاص به‌صورت منسجم و تعیین نقش افراد در گردش کارها، مانند تولیدکننده، فروشنده، خریدار",

            "rodin-financial-system-title" : "سیستم مالی جامع و یکپارچه",
            "rodin-financial-system-list-1" : "امکان تعریف بانک و پوز بانکی و همچنین امکان تعریف دسته‌چک و برگه‌های چک.",
            "rodin-financial-system-list-2" : "درگاه پرداخت مستقیم برای بانک‌های سامان و پاسارگاد.",
            "rodin-financial-system-list-3" : "امکان ورود اطلاعات برای فرم های دریافت و پرداخت، به صورت گروهی و چاپ چک صادرشده.",
            "rodin-financial-system-list-4" : "امکان گزارش گیری و تعیین وضعیت چک در هر مرحله.",
            "rodin-financial-system-list-5" : "حسابداری متصل به سیستم فروش، با امکان ثبت اسناد فروش به صورت خودکار در لحظه‌ی ثبت.",
            "rodin-financial-system-list-6" : "امکان انجام عملیات حسابداری، به‌صورت دائمی، یا ادواری.",
            "rodin-financial-system-list-7" : "محاسبه‌ی بهای تمام شده برای کالای فروش رفته، براساس فرمول میانگین کتغیر موزون.",
            "rodin-financial-system-list-8" : "تعریف دوره‌ی مالی و ثبت اسناد افتتاحیه و اختتامیه به‌صورت خودکار و ایجاد محدودیت برای عملکرد سیستم بر مبنای دوره‌ی مالی.",
            "rodin-financial-system-list-9" : "امکان تعریف نوبت صندوق و حسابرسی به تفکیک صندوقداران با امکان محاسبه‌ی خودکار و مازاد صندوق.",
            "rodin-financial-system-list-10" : "امکان قفل فاکتورهای بخش فروش، بر اساس تاریخ رسیدگی به حساب‌ها، به ترتیبی که ویرایش فاکتورها غیرفعال خواهد شد.",

            "rodin-customer-club-title" : "باشگاه مشریان منعطف",
            "rodin-customer-club-list-1" : "امکان تعریف قوانین تخفیف کالا بر اساس زمان، کالا و وضعیت مشتری، به صورت نامحدود و اعمال خودکار در صندوق فروشگاه.",
            "rodin-customer-club-list-2" : "تعریف نحوه‌ی امتیازدهی به اعضای باشگاه، بر اساس عملکرد و حجم خرید.",
            "rodin-customer-club-list-3" : "امکان ارسال sms و اطلاع رسانی از میزان امتیاز و اعتبار بعد از هر خرید.",
            "rodin-customer-club-list-4" : "امکان ارسال پیام تبریک تولد برای اعضاء، به صورت خودکار.",

            "rodin-centralized-purchase-title" : "پیشنهاد خرید متمرکز(سفارشات)",
            "rodin-centralized-purchase-list-1" : "پیشنهاد خرید کالا توسط سیستم به صورت خودکار، براساس نحوه‌ی فروش کالا، با در نظر گرفتن سرعت فروش کالا.",
            "rodin-centralized-purchase-list-2" : "مدیریت منسجم کالای سفارش داده شده به تامین کننده و زمان دریافت کالا، با امکان ثبت پیش خرید، به منظور کنترل صحت عملکرد تامین کننده.",
            "rodin-centralized-purchase-list-3" : "امکان تایید مدیر بخش، قبل از سفارش گذاری برای تامین کالا.",
            "rodin-centralized-purchase-list-4" : "ثبت فاکتورهای خرید و مرجوعی با امکانات راحت و عطف به فاکتورهای مرجع.",
            "rodin-centralized-purchase-list-5" : "امکان تعیین زمان تسویه در فاکتور خرید و بودجه بندی بر اساس تخمین تسویه.",
            "rodin-centralized-purchase-list-6" : "فرم کاربردی برای ورود اطلاعات کالا در ثبت فاکتورها، با امکان جستجو بر اساس نام کالا، یا بارکد کالا.",
            "rodin-centralized-purchase-list-7" : "نمایش اطلاعات تکمیلی در زمان ثبت فاکتور خرید، به ترتیبی که از خرید کالای اشتباه جلوگیری شود.",
            
            "rodin-cafe-title" : "کافه و رستوران",
            "rodin-cafe-list-1" : "مدیریت و صدور فاکتور فروش، بر مبانی فضای موجود و میزها.",
            "rodin-cafe-list-2" : "متصل به باشگاه مشتریان فروشگاه.",
            "rodin-cafe-list-3" : "محاسبه‌ی بهای تمام شده‌ی کالا، بر اساس فرمول ساخت.",
            "rodin-cafe-list-4" : "انبار مواد اولیه و کالای آماده‌ی فروش، به صورت تفکیکی",

            "rodin-capillary-sales-title" : "فروش مویرگی",
            "rodin-capillary-sales-list-1" : "اپلیکیشن اندروید جهت صدور فاکتور فروش، یا پیش‌فاکتور به صورت آنلاین در محل مشتری.",
            "rodin-capillary-sales-list-2" : "نمایش موجودی به صورت آنلاین و به‌روز.",
            "rodin-capillary-sales-list-3" : "سازگار با تمامی خطوط اینترنت بدون نیاز به بستر اینترنت ویژه.",
            "rodin-capillary-sales-list-4" : "جستجوی سریع.",
            "rodin-capillary-sales-list-5" : "نمایش اطلاعات کالا به صورت کامل، به همراه تصویر.",

            "rodin-Store-title" : "انبار",
            "rodin-Store-list-1" : "امکان ویرایش اطلاعات کالا به صورت گروهی.",
            "rodin-Store-list-2" : "چندانباره بودن نرم افزار، به همراه امکان تعریف کد محل و اتصال به کالاها.",
            "rodin-Store-list-3" : "امکان انبارگردانی به صورت بخش بخش، بر مبنای کد محل، به سادگی و با صرف زمان کوتاه(امکان انبارگردانی به صورت روزانه).",
            "rodin-Store-list-4" : "ثبت رسید و حواله‌ی انبار، به صورت دستی یا خودکار.",
            "rodin-Store-list-5" : "امکان تولید برچسب برای کالاهای سازگار با پرینترهای مخصوص چاپ برچسب.",
            
            "ai-introduction-title" : "دپارتمان هوش مصنوعی",
            "ai-introduction-desc" : "واحد هوش مصنوعی یکم‌سافت به تحقیق و توسعه الگوریتم‌های جدید هوش مصنوعی، و پیاده‌سازی و تغییر در الگوریتم‌های موجود می‌پردازد. کار پژوهش بر روی الگوریتم‌های جدید انجام می‌شود، و آن‌ها مطابق با نیازهای جامعه فارسی‌زبان بومی‌سازی می‌شوند. همچنین این واحد الگوریتم‌های خود را هم توسعه می‌دهد.",

            "ai-products-title" : "دو محصولی که در این واحد توسعه داده شده‌اند به این ترتیب هستند:",

            "ai-products-shiva-title" : "شیوا",
            "ai-products-shiva-desc" : "موتور تبدیل متن به گفتار یکم‌سافت، که متن خام را گرفته، و آن را با صدایی نزدیک به صدای انسان می‌خواند و تبدیل به فایل صوتی می‌کند. برای این موتور، از ماژول‌های متعددی در زمینه پردازش متون فارسی، تبدیل متن به واج، و تبدیل واج به صدا استفاده شده است. در این ماژول‌ها از روش‌های یادگیری عمیق و پردازش زبان‌های طبیعی بهره گرفته شده است، و الگوریتم‌های موجود به شکلی تغییر یافته‌اند که بتوانند تا حد ممکن، لحنی نزدیک به گویندگان زبان فارسی داشته باشند. دقت شیوا با دقت جدیدترین موتورهای تبدیل متن به گفتار در زبان‌های مختلف دنیا قابل مقایسه است. با استفاده از شیوا، کتاب‌های متنی به کتاب‌های صوتی تبدیل می‌شوند و در اختیار کاربران قرار می‌گیرند. همچنین از شیوا به عنوان یک محصول مجزا هم استفاده می‌شود.",
            
            "ai-products-recommender-title" : "سیستم پیشنهادگر",
            "ai-products-recommender-desc" : "در این سیستم که برای توصیه اقلام به کاربران استفاده می‌شود، روش‌‌های پیشین ارتقا داده شده‌اند، و کار توصیه اقلام به صورت شخصی‌سازی‌شده انجام می‌شود، به این ترتیب که برای هر کاربر، بر اساس زمان، علاقمندی‌های کاربر، ویژگی‌های اقلام، و روندهای موجود در بازار، اقلام مورد نظر پیشنهاد می‌گردند. این سیستم پیشنهادگر عملکرد خوبی از خود نشان داده است.",

            "testomonial-davood-name": "داود کیانی",
            "testomonial-davood-title": " مدیر عامل اجرایی",
            "testomonial-davood-desc": "Products which has best Performance",

            "testomonial-faryar-name": "فریار فاطمی",
            "testomonial-faryar-title": "مدیر ارشد فروش",
            "testomonial-faryar-desc": "Products which has best Performance",

            "testomonial-farima-name": "فریما لاری",
            "testomonial-farima-title": "مدیر ارشد تولید",
            "testomonial-farima-desc": "Products which has best Performance",

            "testomonial-erfan-name": "عرفان محمدی",
            "testomonial-erfan-title": "مدیر ارشد تکنولوژی",
            "testomonial-erfan-desc": "Products which has best Performance",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // // if you're using a language detector, do not define the lng option
        // lng: localStorage.getItem('lang') === 'fa' ? 'fa' : 'en',
        lng: 'en',
        // lng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;