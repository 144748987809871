import React from 'react'
import { useTranslation } from "react-i18next";
import { Slide } from "react-slideshow-image";
import { Zoom } from 'react-slideshow-image';

import banner01 from "../assets/png/visionbook-banner-01.png";
import banner02 from "../assets/png/visionbook-banner-02.png";
import banner03 from "../assets/png/visionbook-banner-03.png";

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  minHeight: '380px'
}

const slideImages = [
  {
    url: banner01,
    caption: 'Slide 1'
  },
  {
    url: banner02,
    caption: 'Slide 2'
  },
  {
    url: banner03,
    caption: 'Slide 3'
  },
];

const responsiveSettings = [
  {
      breakpoint: 800,
      settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
  },
  {
      breakpoint: 500,
      settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
  },
  {
    breakpoint: 300,
    settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        height: '180px'
    }
}
];

const buttonStyle = {
  width: "30px",
  background: 'none',
  border: '0px'
};

const properties = {
  prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
  nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
}
function Welcome() {
  const { t } = useTranslation();
  return (
    <div className="slide-container">
        <Slide {...properties} slidesToScroll={2} slidesToShow={2} indicators={true} responsive={responsiveSettings}>
         {slideImages.map((slideImage, index)=> (
            <div key={index} className="rounded-4">
              <div className="rounded-4" style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                {/* <span style={spanStyle}>{slideImage.caption}</span> */}
              </div>
            </div>
          ))} 
        </Slide>
      </div>
  )
}

export default Welcome