import React from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import HoverImage from "react-hover-image";

import SideLine from "./sideLine";
import SegmentHeader from "./segmentHeader";

function AboutUs() {
  const { t } = useTranslation();

  // function backgroundImageWelcome() {
  //   let elem = document.getElementById("bg-img-03");
  //   let scrollY = window.scrollY / 100;
  //   elem.style.backgroundSize = 80 + scrollY + "%";
  // }
  // window.addEventListener("scroll", backgroundImageWelcome);

  return (
    <div style={{marginTop: '220px'}}>
      <section id="about-us">
        <div className="container d-flex align-items-cente">
          <div className="row">
            <div className="col col-12 col-xs-12 col-sm-12 col-md-8">
              <SegmentHeader
                title={t("about-us-title")}
                desc={t("about-us-desc")}
              />
            </div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-4">
              <div className="card p-4 mt-5 text-light">
                <div className="row h-100" role="alert">
                  <span>{t("about-us-users-number")}</span>

                  <div className="col-4">
                    <h4 className="mt-3">
                      <CountUp duration={3} enableScrollSpy={true} end={1102} />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
